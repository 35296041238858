import React from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"

export default function Home() {
	return (
		<Layout>
			<Helmet>
                <title>Page not found - Yagmin Properties LLC</title>
            </Helmet>
            <h1>404 Page not found</h1>
		</Layout>
	)
}
